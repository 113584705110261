<script lang="ts" setup>
defineOptions({
  name: 'AppContentTitle',
})
const props = withDefaults(
  defineProps<{
    areaName: string
    logo?: string
    welcomeMessage?: string
  }>(),
  {
    welcomeMessage: 'Willkommen im',
  },
)
</script>

<template>
  <div class="px-15">
    <img v-if="props.logo" :src="props.logo" class="w-50" alt="area-logo">
    <h1 class="mt-9 text-20 text-ppGray-900 font-600 leading-none">
      {{ props.welcomeMessage }} <br> {{ props.areaName }}!
    </h1>
  </div>
</template>

<style scoped>
h1 {
  letter-spacing: -0.1rem
}
</style>

<script lang="ts" setup>
defineOptions({
  name: 'AppContent',
})
const props = defineProps<Props>()
interface Props {
  /**
   * the logo image url, it's supposed to be in the public dir or an external URL
   */
  logo?: string
  /**
   *  the area name displayed in the welcome message
   */
  areaName: string
  /**
   * an optional welcome message that will be displayed in the content title
   */
  welcomeMessage?: string
  /**
   * an array of objects that displays a label and an amount for the price, one object per line
   */

  /**
   * an optional image of a car and notes that will be displayed in the notes section
   */
  carImage?: string
  notes?: string

  pricing: {
    label: string
    amount: number
    from?: boolean
  }[]
}
</script>

<template>
  <div class="">
    <AppContentTitle :area-name="props.areaName" :logo="props.logo" :welcome-message="props.welcomeMessage" />
    <AppContentPriceContainer :pricing="props.pricing" />
    <AppContentNotes v-if="carImage" :car-image="props.carImage" :notes="props.notes" />
  </div>
</template>

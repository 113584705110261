<script lang="ts" setup>
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const route = useRoute()
const layout = computed(() => {
  return route.meta.layout || 'DefaultLayout'
})
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>

  <VueQueryDevtools />
</template>

<script lang="ts" setup>
defineOptions({
  name: 'AppContentPriceItem',
})
const props = withDefaults(defineProps<{
  label: string
  amount: number
  from?: boolean
}>(), {
  from: false,
})

const formattedAmount = computed(() => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(props.amount)
})

const displayedAmount = computed(() => {
  return props.from ? `je ${formattedAmount.value}` : formattedAmount.value
})
</script>

<template>
  <div class="flex justify-between text-10 text-white font-600 leading-none">
    <p>{{ props.label }}</p>
    <p>{{ displayedAmount }}</p>
  </div>
</template>

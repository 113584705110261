import { createRouter, createWebHistory } from 'vue-router'
import PalaisVest from '@/views/PalaisVest.vue'
import GeraArcaden from '@/views/GeraArcaden.vue'
import HomeView from '@/views/Home.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/palais-vest',
      name: 'palais-vest',
      component: PalaisVest,
    },
    {
      path: '/gera-arcaden',
      name: 'gera',
      component: GeraArcaden,
    },
  ],
})

export default router

<script lang="ts" setup>
defineOptions({
  name: 'AppContentNotes',
})
const props
  = defineProps<{
    carImage?: string
    notes?: string
  }>()
</script>

<template>
  <div class="bg-pattern flex items-center justify-center p-5">
    <div class="w-96% rounded-xl bg-white p-6">
      <div class="flex items-center justify-between">
        <p class="text-[2.4375rem] text-gray-900 font-semibold leading-none">
          {{ props.notes }}
        </p>
        <img v-if="props.carImage" :src="props.carImage" class="h-[4.3689rem] w-[11.875rem]" alt="car-image">
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-pattern {
 background: url('/dots_background.svg') repeat;
}
</style>
